import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import Image from "../components/image"
import * as Sign from "../components/signature"

const Contents = styled.div({
  maxWidth: 960,
  margin: "0 auto",
})

const Grid = styled.div({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
  alignItems: "center",
  gap: 20,
})

const SecondPage = () => (
  <Layout>
    <Contents>
      <SEO title="About" />
      <Grid>
        <div style={{ width: "100%", maxWidth: 383, justifySelf: "center" }}>
          <Image filename="profile.png" alt="image" />
        </div>
        <div>
          <ul>
            <li>Webデザイン歴10年</li>
            <li>フロントエンドエンジニア歴10年</li>
            <li>
              Web制作会社にて、デザイナー/フロントエンドエンジニアとして6年半活動
            </li>
            <li>
              現在はフロントエンドエンジニアをメインとしてwebアプリケーション開発者として活動中。状況に応じてデザイン制作も行っています。
            </li>
          </ul>
          <p>
            このサイトは
            <a href="https://www.gatsbyjs.org/" target="_blank">
              GatsbyJS
            </a>{" "}
            +{" "}
            <a href="https://github.com/emotion-js/emotion" target="_blank">
              emotion
            </a>{" "}
            +{" "}
            <a href="https://firebase.google.com/?hl=ja" target="_blank">
              FireBase
            </a>
            で構築しています。
          </p>
          <div style={{ textAlign: "center" }}>
            <Sign.View />
          </div>
        </div>
      </Grid>
    </Contents>
  </Layout>
)

export default SecondPage

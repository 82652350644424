import React from "react"

const Sign = () => (
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 246.88 165.84"
    width="200"
  >
    <g>
      <g>
        <g>
          <path
            d="M0.12,29.74c13.48,43.61,35.79,84.05,65.43,118.76c2.09,2.45,5.62-1.1,3.54-3.54C40.02,110.93,18.17,71.18,4.95,28.41
				C4,25.35-0.83,26.66,0.12,29.74L0.12,29.74z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M52.73,105.54c4.94,8.18,12.86,18.36,23.82,14.32c10.15-3.74,17.76-16.73,21.83-26.06c-1.52-0.2-3.05-0.4-4.57-0.6
				c1.47,7.65,2.95,15.3,4.42,22.94c0.61,3.16,5.43,1.82,4.82-1.33c-1.47-7.65-2.95-15.3-4.42-22.94c-0.39-2-3.72-2.55-4.57-0.6
				c-3.33,7.63-7.93,14.79-14.14,20.43c-9.89,8.98-17.67-0.07-22.87-8.68C55.39,100.26,51.06,102.78,52.73,105.54L52.73,105.54z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M135,97.61c-3.01-4.55-9.84-12.9-16.24-11.55c-6.41,1.35-12.27,12.49-12.84,18.48c-0.66,6.92,6.98,6.23,11.65,5.55
				c7.2-1.06,14.17-3.35,18.25-9.76c1.11-1.75-0.76-4.45-2.82-3.67c-6.82,2.56-7.19,11.56-1.53,15.68c7.4,5.39,18.36,1.92,25.07-2.9
				c2.59-1.86,0.1-6.2-2.52-4.32c-3.85,2.76-8.31,4.78-13.13,4.83c-2.28,0.02-5.14-0.27-6.9-1.95c-1.65-1.57-2.61-5.42,0.35-6.53
				c-0.94-1.22-1.88-2.45-2.82-3.67c-2.41,3.79-6.22,5.51-10.45,6.56c-2.49,0.62-9.62,2.9-9.86-0.49
				c-0.22-3.01,4.58-10.61,7.24-11.94c4.72-2.35,10.11,4.97,12.26,8.21C132.45,102.8,136.78,100.3,135,97.61L135,97.61z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M140.53,2.41c-2.15,45.22,2.68,90.57,14.29,134.33c0.82,3.11,5.65,1.79,4.82-1.33c-11.5-43.36-16.24-88.2-14.11-133
				C145.68-0.81,140.68-0.8,140.53,2.41L140.53,2.41z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M169.62,86.97c-10.34,1.62-19.14,7.75-24.44,16.74c-0.95,1.61,0.26,3.8,2.16,3.76c11.26-0.24,22.47-1.07,33.64-2.48
				c3.15-0.4,3.19-5.4,0-5c-11.17,1.41-22.38,2.24-33.64,2.48c0.72,1.25,1.44,2.51,2.16,3.76c4.69-7.96,12.36-13.01,21.46-14.44
				C174.12,91.29,172.77,86.47,169.62,86.97L169.62,86.97z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M180.83,33.18c-3.6,32.46-3.3,65.18,0.89,97.57c0.41,3.15,5.41,3.19,5,0c-4.2-32.39-4.5-65.11-0.89-97.57
				C186.18,29.98,181.18,30.01,180.83,33.18L180.83,33.18z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M201.93,80.75c-9.31,3.41-17.53,8.64-24.48,15.72c-1.62,1.65-0.33,4.08,1.77,4.27c10.96,0.98,21.94,1.29,32.93,0.92
				c3.21-0.11,3.22-5.11,0-5c-11,0.37-21.97,0.06-32.93-0.92c0.59,1.42,1.18,2.85,1.77,4.27c6.35-6.47,13.76-11.31,22.28-14.43
				C206.26,84.48,204.96,79.65,201.93,80.75L201.93,80.75z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M211.62,78.68c3.3,9.35,9.89,16.97,18.84,21.32c2.88,1.4,5.42-2.91,2.52-4.32c-7.76-3.77-13.66-10.16-16.54-18.33
				C215.38,74.33,210.54,75.63,211.62,78.68L211.62,78.68z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M243.19,83.58c-17.43,11.18-34.45,22.88-50.75,35.65c-10.45,8.19-21.06,15.8-32.62,22.39
				c-12.28,7.01-24.93,13.36-37.6,19.62c-2.88,1.43-0.35,5.74,2.52,4.32c18.41-9.11,37.15-18.21,54.12-29.88
				c10.89-7.5,20.96-16.14,31.64-23.93c11.46-8.35,23.28-16.19,35.21-23.85C248.42,86.17,245.91,81.84,243.19,83.58L243.19,83.58z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const View = Sign
